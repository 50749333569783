import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass']
})
export class FooterComponent implements OnInit {
  
  @Input() data :any;
  @Input() contacto :any;
  constructor() { }
  URL_FILES:string=environment.filesUrl; 
  ngOnInit() {
  }

}
